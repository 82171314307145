import React from 'react'
import Styles from './Footer.module.css'
const Footer = () => {
  return (
    <div className={` text-center ${Styles.footer}`}>
     CET© 2023. All Rights Reserved
    </div>
  )
}

export default Footer
