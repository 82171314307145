import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Components/Home';
function App() {
  return (
   <>
   <div className='App_Container'>
    <Home/>
   </div>
   </>
  );
}

export default App;
